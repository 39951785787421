/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import SEO from '@/components/Seo'
import { fade } from '@/utils/transitions'
import { cleanProps, cleanPropsWithRelations } from '@/utils'
import Blocks from '@/utils/Blocks'
import useConstant from '@/hooks/useConstant'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function Page({
	pageContext: { media, wordpress_id },
	data: {
		page: { blocks, yoast, featured_media },
		caseStudies: { nodes: caseStudies },
		posts: { nodes: posts },
		quotes: { nodes: quotes }
	}
}) {
    const data = useConstant(() =>
    cleanPropsWithRelations(
        blocks,
        media,
        {
            'acf/case-study-card-slider': {
                data: [...caseStudies, ...posts],
                key: 'case_studies'
            },
            'acf/quote-collection-block': { data: quotes, key: 'quotes' }
        }
		)
        )
	return (
		<motion.div
			variants={ fade }
			initial="initial"
			// className="wrapper"
			animate="enter"
			exit="exit"
		>
			<SEO { ...yoast } image={ featured_media } />
			<Blocks data={ data } media={ media } currentPage={ wordpress_id } />
		</motion.div>
	)
}

Page.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired,
		caseStudies: T.caseStudies.isRequired,
		posts: T.posts.isRequired,
		quotes: T.quotes.isRequired
	})
}

export const query = graphql`
	query GenericPageQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
		caseStudies: allWordpressWpCaseStudy(filter: { slug: { ne: "template" } }) {
			nodes {
				...caseStudyQuery
			}
		}
		posts: allWordpressPost(filter: { slug: { ne: "template" } }) {
			nodes {
				...postThumbQuery
			}
		}
		quotes: allWordpressWpQuotes(limit: 100) {
			nodes {
				...quoteQuery
			}
		}
	}
`

export default Page
